<template>
  <div class="industryTrends">
    <img src="~@/assets/img/trade@2x.jpg" class="head_img"/>
    <div class="body">
      <div class="first">
        <div @click="$router.push('/industryTrendsDetails/1')">
          <img src="~@/assets/img/tarde_1@2x.jpg"/>
          <div>LPR“双降”，房地产信贷端正趋向“由紧转松” ｜ 宏观</div>
          <div>5年期LPR 21个月以来首次下调“2022年开年以来，信贷政策利好不断。在 2021 年两次全面降准以及 1 月 17 日下调 MLF 利率和逆回购汇率后， 1 月 20 日，新一期贷款市场报价利率（ LPR
            ）出炉。
          </div>
        </div>
        <div @click="$router.push('/industryTrendsDetails/2')">
          <img src="~@/assets/img/tarde_2@2x.jpg"/>
          <div>亚洲医疗健康高峰论坛 ：数字健共体催生中国式“联合健康”</div>
          <div>国际大咖港岛论“健”：中国式“联合健康”在如何炼成。
            11月24日，首届亚洲医疗健康高峰论坛举行，香港特区行政长官林郑月娥、香港贸发局主席林建岳博士及WHO首席科学家苏米娅·斯瓦米纳坦等来自世界的相关官员、科研专家、商界与投资领袖与会，共同探讨亚洲地区医疗健康产业的数字化进程。
          </div>
        </div>
        <div @click="$router.push('/industryTrendsDetails/3')">
          <img src="~@/assets/img/tarde_3@2x.jpg"/>
          <div>健康幸福过大年</div>
          <div>
            新春临近，年味愈浓。春节长假是阖家团圆的日子，也是放松身心的好时机。当前，境外疫情蔓延，我国依旧面临较大疫情输入风险；一些地方还存在散发疫情，疫情扩散的风险依旧不容忽视。因此，在欢喜过节的同时，要做好个人防护，坚持戴口罩、“一米线”、勤洗手等卫生习惯。
          </div>
        </div>
      </div>
      <div class="second">
        <div class="list" @click="$router.push('/industryTrendsDetails/5')">
          <div class="left">
            <div>中小地产企业如何活下去 | 地产新势</div>
            <div>2022-01-27</div>
            <div>过去的2021年，房地产经历冰火两重天，上半年市场上行，房企拿地积极；下半年市场转冷，流动性风险加剧，尤其是四季度以来，包括恒大、佳兆业、阳光城、奥园等多家规模房企深陷危机。</div>
          </div>
          <img src="~@/assets/img/tarde_c1@2x.png">
        </div>
        <div class="list" @click="$router.push('/industryTrendsDetails/6')">
          <div class="left">
            <div>2022年医疗健康五大趋势，这些设备是风口</div>
            <div>2021-11-18</div>
            <div>美国《福布斯》双周刊网站在近日的报道中，列出了2022年医疗保健领域的五大技术趋势：远程医疗、个性化医疗、基因组学和可穿戴设备等领域将迎来高速增长，业界人士也将进一步利用人工智能（AI）、云计算、虚拟现实和物联网（IoT）等技术，开发并提供新的更好的疗法和服务。</div>
          </div>
          <img src="~@/assets/img/tarde_c2@2x.png">
        </div>
        <div class="list" @click="$router.push('/industryTrendsDetails/7')">
          <div class="left">
            <div>楼市展望与行业前瞻：地产行业在阵痛中寻找未来</div>
            <div>2021-11-08</div>
            <div>今年的房地产市场没有“金九”，也没有“银十”。
              克而瑞研究中心发布的2021年1月-9月房地产企业销售TOP100排行榜显示，百强房企单月全口径销售金额同比下降36%。10月，房地产市场持续降温，逾8成百强房企单月业绩同比降低，其中44家房企业同比降幅大于30%。
            </div>
          </div>
          <img src="~@/assets/img/tarde_c3@2x.png">
        </div>
        <div class="list" @click="$router.push('/industryTrendsDetails/4')">
          <div class="left">
            <div>全球独角兽企业500强报告发布：医疗健康赛道前景看好</div>
            <div>2021-09-25</div>
            <div>中新网青岛9月25日电 由青岛市人民政府和中国人民大学中国民营企业研究中心联合主办的“第三届全球独角兽企业500强大会(2021)”日前在青岛市举办。会议期间全球独角兽企业500强大会秘书长解树江发布了《全球独角兽企业500强发展报告(2021)》。</div>
          </div>
          <img src="~@/assets/img/tarde_c4@2x.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "industryTrends"
}
</script>

<style scoped lang="scss">
.industryTrends {
  .body {
    padding-top: 101px;
    width: 65%;
    margin: 0 auto;

    .first {
      display: flex;
      justify-content: space-between;

      & > div {
        cursor: pointer;
        flex: .32;

        & > img {
          width: 100%;
          height: 215px;
        }

        & > div {
          &:nth-child(2) {
            font-size: 19px;
            font-weight: bold;
            color: #BE9A7E;
            line-height: 26px;
            margin: 20px 0;
            overflow: hidden;
            display: -webkit-box; //将元素设为盒子伸缩模型显示
            -webkit-box-orient: vertical; //伸缩方向设为垂直方向
            -webkit-line-clamp: 1; //超出3行隐藏，并显示省略号
          }

          &:nth-child(3) {
            font-size: 14px;
            font-weight: 400;
            color: #A09F9F;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
    }

    .second {
      margin-top: 124px;
      margin-bottom: 131px;

      .list {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ECEDEE;
        padding: 50px 0;
        cursor: pointer;

        &:last-child {
          border-bottom: 1px solid #ECEDEE;
        }

        img {
          width: 405px;
          height: 180px;
        }

        .left {
          margin-right: 33px;

          & > div {
            &:nth-child(1) {
              font-size: 19px;
              font-weight: bold;
              color: #4C4C4C;
              line-height: 26px;
            }

            &:nth-child(2) {
              font-size: 16px;
              font-weight: 500;
              color: #DFDFDF;
              line-height: 20px;
              padding: 20px 0;
            }

            &:nth-child(3) {
              font-size: 14px;
              font-weight: 400;
              color: #686868;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}
</style>