<template>
  <div class="industryTrendsDetails">
    <img src="~@/assets/img/detail@2x.jpg" class="head_img"/>
    <div class="body">
      <template v-if="$route.params.type === '1'">
        <div>LPR“双降”，房地产信贷端正趋向“由紧转松” ｜ 宏观</div>
        <div>作者：丁祖昱评楼市 来源：36氪</div>
        <div>5年期LPR 21个月以来首次下调<br/><br/>
          “2022年开年以来，信贷政策利好不断。<br/><br/>
          在 2021 年两次全面降准以及 1 月 17 日下调 MLF 利率和逆回购汇率后， 1 月 20 日，新一期贷款市场报价利率（ LPR ）出炉。<br/>
          1年期和5年期以上LPR较上一期分别下调10个和5个基点，其中，将5年期贷款市场报价利率（LPR）从4.65%下调至4.6%，为2020年4月20日以来首次下调。
          LPR下行将带动企业融资、个人住房贷款利率降低。<br/>
          央行本周内三次明确释放“宽货币”信号，开年以来两次降息对于提振市场信心有着至关重要的作用，货币政策窗口期已至。
          2022年保持货币信贷总量稳定增长主要目的是维稳经济，当前我国经济的主要下行压力来自房地产，因而保持房地产信贷投放稳定就变得尤为重要，货币政策要“充足发力”、“靠前发力”，也预示着“政策底”已至，
          房地产信贷端正趋于“由紧转松” ，本轮MLF、LPR降息或许不是终点，一季度 不排除有进一步降准降息的可能。 ”<br/>
          <h3> 01.5年期LPR 21个月以来首次下调</h3>
          LPR调整来看，本次有两大特别之处。<br/><br/>
          首先，1年期LPR出现罕见的连续调整。LPR最近一次下调是在12月20日，彼时1年期LPR下调5个基点至3.80%，结束连续19个月的“按兵不动”，紧接着1月再度下调，这在LPR利率改革尚属首次。<br/><br/>
          其二，1年期和5年期以上LPR“双降”，且5年期LPR利率下调5个基点至4.6%，距离上次调整已时隔21个月。<br/><br/>
          值得注意的是，5年期LPR 自2020年4月以来时隔21个月首次下调至4.6%。最直观的影响是购房者按揭，但又不仅只影响按揭，LPR下行将带动个人住房贷款利率降低，同时降低企业融资成本。<br/><br/>
          在当前的利率体系中，LPR属市场利率范畴，但因其对市场主体融资成本特别是银行贷款成本具有重大影响，因此具有较强的政策信号意义。而5年期LPR下调对降低全社会融资成本的覆盖面更大，包括个人按揭贷款和企业中长期贷款。<br/><br/>
          这意味着，信贷政策由此前的居民端（提高个人按揭贷款额度、缩短放贷周期、下调房贷利率等）逐步惠及企业端（降低企业融资成本），避免因局部企业“爆雷”造成系统性危机，进而全面重铸房地产行业信心。<br/><br/>

          <h3>02. LPR利率非对称下调意在“稳增长”</h3><br/><br/>
          实际上，基于近期央行多次表态，以及本周内MLF超量续作且下调政策利率，而从历次调整来看，LPR均会与MLF利率、逆回购操作利率同步调整。<br/><br/>
          1月18日，对于LPR是否会同步调整的问题，央行货币政策司司长孙国峰在国新办发布会上表示，LPR报价行报价时综合考虑自身资金成本、风险溢价和市场供求等因素，LPR会及时充分反映市场利率变化，引导企业贷款利率下行，有力推动降低企业综合融资成本。<br/><br/>
          实际上，市场对本次LPR利率下降已有充分预期，关注的焦点在于长短期LPR利率是否对称下调，以及调整幅度。<br/><br/>
          本次长短期LPR利率非对称下调，加之上一次12月份的调整，1年期LPR较去年4月累计下调15BP，5年期LPR下调5BP，两者利差走阔10BP。<br/><br/>
          此次调整实际传递出两方面的信号：首先，“稳增长”已被摆在更加突出位置，1年期LPR降幅堪比疫情爆发初期；其次，作为房贷利率的定价参考，5年期LPR的下调幅度明显更趋谨慎和保守，表明“房住不炒”仍然是房地产市场整体主基调。<br/><br/>
          <h3>03. 激发市场主体融资需求，购房需求撬动有限</h3><br/><br/>
          5年期LPR利率下调将引导房贷利率步入下行通道，降低居民按揭成本，对促进购房需求释放有积极意义。<br/><br/>
          从个人按揭端来看，按照最新5年期LPR利率4.6%计算，100万房贷，按照等额本息偿还30年计算，月供将减少30元左右。但值得注意的是，LPR下降不会立即生效，而是到次年的1月1日才会按照最新的报价，确定房贷利率。<br/><br/>
          此外，从月度住户中长期增量来看，12月，住户中长期增量为3558亿元，同比少增834亿元，意味着居民按揭贷款需求仍然偏弱。<br/><br/>
          相较于降低首付比例、认房不认贷等加杠杆“猛药”，房贷利率下调只能缓解些许还贷压力，对购房意愿的直接撬动作用比较有限，更值得关注的是其背后宽货币、宽信用对房地产市场预期的影响。<br/><br/>
          重要的是，基于MLF→LPR→贷款利率的利率传导机制，LPR利率下调有助于引导商业银行降低贷款利率，从而降低企业融资成本，激发市场主体融资需求。<br/><br/>
          从12月信贷数据来看，企业融资表现出“中长贷弱、票据融资强”的情况，企业资本开支意愿不足。数据显示，12月企事业单位中长期贷款3393亿元，同比少增2107亿元，反观票据融资增量3190亿元，同、环比分别多增746亿元、1585亿元，银行以票冲贷调节信贷规模意图明显。此外，短期贷款同比转为多增，并成为企业贷款的主要支撑，这与12月1年期LPR利率调降不无关联。<br/><br/>
          <h3>04.“稳增长”下后续降息降准值得期待</h3><br/><br/>
          一周之内，从17日超预期幅度调降MLF政策利率，到18日发布会“三个发力”等积极表态，再到20日调降LPR，央行一系列操作释放出较为明确的货币宽松信号。<br/><br/>
          这既贯彻了中央经济工作会议“政策发力适当靠前”要求，也反映了“稳增长”诉求的迫切性。一方面，国内经济环境面临需求收缩、供给冲击、预期转弱三重重压，另一方面，外部环境美联储加息缩表预期提前，导致国内货币政策放松的窗口期缩短。<br/><br/>
          由于2022年一季度是宝贵的货币政策窗口期，也是“稳增长”的关键时期，结合央行“降准空间变小但仍有空间、宏观杠杆率持续下降创造了货币政策空间”等相关表态，预计一季度内仍有可能会进一步降准、降息。<br/><br/>
          央行非对称下调长短期LPR利率的信号意义很强，突出了“稳增长”的重要性和迫切性，同时进一步强调了“房住不炒”定位。<br/><br/>
          LPR利率下调，将引导商业银行降低贷款利率，从而降低企业融资成本和居民按揭成本。不过房贷利率下降对购房需求的直接撬动作用比较有限，还得看宽货币对市场预期的影响程度；而从企业端，融资成本将进一步降低，以激发市场主体融资需求。<br/><br/>
          2022年保持货币信贷总量稳定增长的目的主要在于维稳经济，货币政策“充足发力”、“靠前发力”也预示着政策窗口期已至，房地产信贷端正趋于“由紧转松”，并且逐步从居民端惠及企业端，重铸房地产行业信心，预计一季度仍有降准降息的可能。<br/><br/>
        </div>
      </template>
      <template v-if="$route.params.type === '2'">
        <div>亚洲医疗健康高峰论坛 ：数字健共体催生中国式“联合健康”</div>
        <div>时氪分享 2021-11-25<br/><br/>国际大咖港岛论“健”：中国式“联合健康”在如何炼成。</div>
        <div>
          11月24日，首届亚洲医疗健康高峰论坛举行，香港特区行政长官林郑月娥、香港贸发局主席林建岳博士及WHO首席科学家苏米娅·斯瓦米纳坦等来自世界的相关官员、科研专家、商界与投资领袖与会，共同探讨亚洲地区医疗健康产业的数字化进程。<br/><br/>
          中国是亚洲最主要的经济力量，广阔的市场以及正进行的规模宏大的医药卫生体制改革，正推动万亿医健市场的重构和升级。与会嘉宾表示，中国正全面推动从“治病为中心”向“健康为中心”转变，这一过程中数字化已成为中国医疗服务体系升级的引擎，以支付改革为切入、以数字化为驱动的健共体的落地，正在催生中国式的“联合健康”。<br/><br/>
          数字化成医健升级新引擎<br/><br/>
          当前，“以治病为中心”向“以健康为中心”转变已成为国家全面深化医疗卫生体制改革的主旋律。在此背景下，如何推动原有医疗服务体系的升级，成为与会嘉宾关注的焦点。<br/><br/>
          <span style="font-weight: bold">“数字化是中国医疗服务体系升级的引擎。”</span>微医集团董事长廖杰远表示。疫情催化下数字医疗成为“必选项”，未来中国医疗服务体系的构建中，他认为数字化亦将发挥日益重要的作用。<br/><br/>
          这一观点获得大会嘉宾的响应。春华资本集团创始人、董事长兼CEO胡祖六谈到，疫情下，几乎一夜间医疗体系就从传统院内、实体的医疗服务，转变成线上医疗服务。数据显示，2020年2月，中国医疗卫生机构诊疗人次同比下跌约40%，而互联网诊疗人次则增加了17倍。不单中国，全世界的医疗系统都在疫情中大量使用数字科技，以提升医疗的韧性。<br/><br/>
          廖杰远提及，2020年新冠疫情期间，微医上线“新冠肺炎实时救助平台”，高峰时平台单日访问量超1100万次，承担武汉40.8万重症慢病患者97%在线复诊购药需求。同时，微医上线“全球抗疫平台”为二百二十多个国家和地区的海外华人、国际友人提供抗疫服务。足可见数字医疗的巨大“潜力”。<br/><br/>
          胡祖六相信医疗数字化转型并非是短暂的，随着民众对数字医疗服务的接受度日益提升、政府一系列支持性政策的推出、大量的投资涌入……都会让这个趋势继续下去。<span style="font-weight: bold">“数字化转型将有机会让亚洲地区成为全球最先进、最有效率、最强韧、最平等的医健市场。”</span>
          <br/><br/>
          从医，到药，再到保，医健体系的数字化升级浪潮难以阻挡。复星国际执行董事兼联席首席执行官陈启宇提及，疫情之后，包括互联网公司、医疗集团、保险公司、IT技术公司、医疗机构乃至政府等各方力量，都在加速数字医疗的应用。阿斯利康全球执行副总裁王磊也认为，数字医疗正变得非常重要，他预计会有越来越多的公司会利用互联网进行危急及慢性病患者的管理。<br/><br/>
          “数字健共体”催生中国的联合健康<br/><br/>
          “当14亿老百姓从以治疗为中心转向以健康为中心，整个支付方式和服务模式发生根本性改变的时候，一个全新的巨大的市场正喷薄而出。”廖杰远说。<br/><br/>
          香港交易所执行董事、集团行政总裁欧冠升谈到，中国医疗支出在未来10年将会有3倍的增长，达到3万亿美元的规模。庞大市场将孕育巨大机遇，越来越多内地医健公司赴港上市。他透露，目前已有79家医健公司在港上市，集资超2000亿元，总市值超6000亿美元，另外还有55家该类型公司正申请在港上市，可见行业增长之快速。<br/><br/>
          随着人口老龄化，医疗服务需求日益增加，将给产业带来更多机会。不过，挑战在于如何给老百姓提供更加可及、可负担的健康服务。苏米娅·斯瓦米纳坦建议，<span style="font-weight: bold;">“我们需要思考如何从国家整体角度做规划，以及如何利用数字科技更好地加强现存的服务，以加快可持续发展目标的实现。”</span>
          <br/><br/>
          今年10月份，国务院医改领导小组印发《关于深入推广福建省三明市经验
          深化医药卫生体制改革的实施意见》，16次提到“推进医疗联合体建设”，医联体建设已成为中国医改主轴。廖杰远认为，中国医疗体系最大的挑战，就是医疗资源的分布不均衡。数字医疗的价值在于，不仅能够把医疗服务能力“送”到基层；更重要的是，<span
            style="font-weight: bold">在医联体建设成为新医改主轴的背景下，数字医疗将成为医联体建设的驱动力，助力实现“以健康为中心”的目标。</span> <br/><br/>
          他以中国天津的实践为例，天津微医互联网医院牵头、协同全市267家基层医疗卫生机构，在2020年共同组建起了紧密型互联网医联体——天津市基层数字健共体，在为居民提供全流程医疗和健康维护服务的同时，探索医保“整体打包付费”“按病种按人头打包付费”等支付方式，根据医疗健康管理质量考核结果，落实“结余留用、超支不补”的激励约束机制，构建起全新的健康责任机制。<br/><br/>
          现场专家认为，中国正在推动以健康为中心的医联体建设，这与国际上的健康管护模式异曲同工。<span style="font-weight: bold">而以天津为代表的“数字健共体”正在推动医联体进入到3.0阶段，其所构建的健康管护体系或催生中国的联合健康。中国作为全球规模最大的统一市场和人口大国，将在数字化的驱动下呈现出比美国联合健康投入更轻、发展更快的趋势，也是中国借助“双循环”实现医疗健康产业弯道超车的一次重要机遇。</span><br/><br/>
          2020年6月，中央全面深化改革委员会第十四次会议指出：“高度重视新一代信息技术在医药卫生领域的应用，重塑医药卫生管理和服务模式。”伴随着国家对数字医疗的重视日深，依托数字化技术为全民构建以健康为中心的健康管护体系正在成为现实，势必将助力健康中国愿景的早日实现。<br/><br/>
        </div>
      </template>
      <template v-if="$route.params.type === '3'">
        <div>健康幸福过大年</div>
        <div>2022-01-28 09:58·光明网</div>
        <div>
          新春临近，年味愈浓。春节长假是阖家团圆的日子，也是放松身心的好时机。当前，境外疫情蔓延，我国依旧面临较大疫情输入风险；一些地方还存在散发疫情，疫情扩散的风险依旧不容忽视。因此，在欢喜过节的同时，要做好个人防护，坚持戴口罩、“一米线”、勤洗手等卫生习惯。<br/><br/>
          今年春节，很多人选择就地过年，居家时间增多，外出活动减少。合理膳食、适当运动、戒烟限酒、心理平衡，是健康的四大基石。希望大家保持健康的生活方式，过一个健康幸福的春节。<br/><br/>
          <span style="font-weight: bold">饮食</span><br/><br/>
          <span style="font-weight: bold"> 既要美味 也要健康</span><br/><br/>
          美味佳肴，觥筹交错。春节的餐桌，总是洋溢着无尽的欢乐与幸福。今年，节日聚餐将以居家为主。如何吃得科学、吃得健康，既享受美味又不引发疾病？日前，记者采访了北京协和医院临床营养科主任医师于康。<br/><br/>
          于康说，肥肉、油脂和甜食确实可以增添膳食的风味，在节日的餐桌上一直扮演着不可替代的角色。一些人平时饮食比较清淡，但节日期间放松控制，这三者吃得过多，能量、脂肪和胆固醇等摄入量显著增高，可能引发或加重高脂血症、冠心病、脂肪肝和肥胖症等。<br/><br/>
          建议少吃肥肉、油脂和甜食，代之以蔬菜、水果、鱼肉、虾仁、瘦肉等。具体做法包括：每日烹调采用植物油，总量不超过25毫升；每日进食肉类尽量控制在3两以内，以瘦肉为主；肉类首选鱼虾，其次为去皮的鸡鸭，最后选择牛羊肉；某餐进食了油炸食物，则下一餐尽量吃清淡点。<br/><br/>
          节日期间，家人团圆聚餐频繁，进食量往往不知不觉就增加了。如何才能做到“饮食有度”？<br/><br/>
          于康指出，一次性摄入大量富含能量、蛋白质、脂肪和糖分的食物，不仅会使其中的大部分营养素（如蛋白质等）无法被充分吸收，还可能引起急性胃扩张，诱发急性胃肠炎、急性胃溃疡穿孔，甚至诱发心脏病、急性胰腺炎等。可以说，暴饮暴食是节日期间饮食的第一大忌。少量多餐，每餐七八分饱，才是健康饮食。<br/><br/>
          如果不得不熬夜，可在晚上12点左右适当加一次餐。夜宵可选用一些易于消化的食物，包括牛奶、酸奶、水果、饼干、烤面包片等，吃些热水饺也不错。值得注意的是，如果午夜进食韭菜馅饺子有可能引起腹胀等。各类奶油甜点、油炸食物等不宜用于加餐。<br/><br/>
          一些人长期处于“高盐”饮食状态，对“咸”的感觉功能逐渐减退，使口味越来越偏咸，春节期间尤甚。如何才能“减盐不减味”？<br/><br/>
          于康认为，每日摄取盐过多，易造成血压升高。一般每人每日摄入盐总量以不超过6克为宜，对伴有高血压、高血糖、心脏病、糖尿病等患者，更须限制到4克以下。<br/><br/>
          选择醋、柠檬汁、番茄酱等调味品，可使食物变得更加有滋有味有色，促进食欲，去腥解腻。但是，有人喜欢食用刺激性较大的调味品（如芥末、辣椒等）和浓肉汤等，虽可满足一时快感，但时间长了对身体不利，可引起胃肠刺激、消化不良、便秘等，甚至还有升高血脂和血尿酸的副作用。<br/><br/>
          橄榄油应成为餐桌上的主要油脂来源。每日用量以不超过30克为宜。橄榄油性质稳定，有极强的抗氧化能力，既适于凉拌食物，也可在高温下烹调。在190摄氏度以下，橄榄油一般不会分解，只要不用于油炸和油煎，其他烹调方法均可使用橄榄油。<br/><br/>
          节日期间，动物性食品摄入较多，易导致一种名为“嘌呤”的物质摄入过多，从而造成高尿酸血症和痛风的发病风险增高。如何控制高嘌呤食物的摄入？<br/><br/>
          于康提醒，含嘌呤多的食物（每100克含嘌呤150—1000毫克）包括：各类动物内脏、沙丁鱼、凤尾鱼、鱼子、浓肉汤等。如出现痛风急性发作，只能吃鸡蛋、精制面粉及含嘌呤少的蔬菜，多吃水果及大量饮水，禁食一切肉类及含嘌呤丰富的食物。对于慢性期痛风患者，每日可进食煮鸡蛋1个、牛奶（可选用脱脂牛奶）250毫升、白肉（如去皮的鸡肉）不超过100克，也可采用水煮肉类，弃其汤食其肉，减少嘌呤摄入。(记者
          白剑峰)<br/><br/>
          春节假期，如何更好地保护眼睛？魏瑞华给了几点建议：<br/><br/>
          首先，学会放松眼睛。一次连续近距离用眼时间不应过长，用眼30分钟左右应休息10分钟左右并远眺，使眼睛放松，同时控制全天电子产品的使用时长。使用电子产品时要养成眨眼习惯，每分钟应该平均眨眼12—15次，可以有效预防干眼。<br/><br/>
          其次，养成爱眼好习惯。姿势端正，注意距离，不在乘车、走路、卧床和太阳光直射或暗光下阅读或写字。<br/><br/>
          第三，改善用眼环境。保持阅读环境中适宜的光亮度和对比度，照明应无眩光或闪烁，台灯放在写字台的左上方，阅读字体印刷清晰，大小适中。<br/><br/>
          第四，加强阳光体育锻炼。春节适逢立春前后，天气转暖，适宜开展体育锻炼，增加室外活动时间。让眼睛多接触自然光，可以有效预防和控制儿童近视。<br/><br/>
          “均衡膳食营养也非常重要，应多吃胡萝卜、西红柿、葡萄等新鲜的蔬菜水果。”魏瑞华说，眼睛是心灵的窗户，人们有80%的外界信息是通过眼睛获取的，有一双明亮健康的眼睛才能更好地学习和生活。(记者 孙秀艳)<br/><br/>
          来源： 人民日报<br/><br/>
        </div>
      </template>
      <template v-if="$route.params.type === '4'">
        <div>中小地产企业如何活下去 | 地产新势</div>
        <div>经济观察报 2022-01-27 16:26</div>
        <div>过去的2021年，房地产经历冰火两重天，上半年市场上行，房企拿地积极；下半年市场转冷，流动性风险加剧，尤其是四季度以来，包括恒大、佳兆业、阳光城、奥园等多家规模房企深陷危机。<br/><br/>
          <br/><br/>
          2021年先后有13家房企出现信用债违约，金额创下历史新高；2022年不到一个月，又有奥园、禹洲和大发等三家房企信用债违约。<br/><br/>
          从2021年底，监管层就开始积极化解房地产流动性风险，开发贷、按揭贷、信用债、中票等融资通道陆续放开；部分城市预售资金监管力度也有所松动，但由于落地节奏和效果与预期存在一定差距，房地产流动性并没有得到实质性改善。<br/><br/>
          2022年，对诸多房企来说，依然是不确定的一年，一部分房企开始积极自救，通过优化组织结构、裁员等方式减少支出，通过出售资产、项目等方式缓解流动性和避免债务违约，通过更积极的销售策略以及更新的产品能力适应新的市场行情……<br/><br/>
          经历这一次流动性风险的淬炼，房地产企业、行业、市场必须深刻蜕变，以面对房地产良性循环的新要求了。<br/><br/>
          经济观察报 记者 丁文婷 虎年年关，一家小型地产企业的投拓负责人陈生正忙着做2022年的拿地计划，计划将在这几天敲定并提交。<br/><br/>
          “目标是在某一线城市拿两块。”陈生计划。<br/><br/>
          “太少了，怎么也得3块吧。”公司领导回应。<br/><br/>
          这让陈生“压力山大”，不是不想拿，而是没有钱。公司可供调配的拿地资金有限，陈生需要像切蛋糕一样将他们划分开，再一一调配，如果选择总价在10亿元的地块，那么最多可以拿两块。如果选择便宜一点的，比如6亿元左右的，则最多可以拿三块。<br/><br/>
          除了缴纳一定比例的地块保证金，一些城市还要求企业将起拍价110%的资金打入监管账户，而这笔钱一般都在开拍后10个工作日才能退回，这大大限制了陈生所在企业报名的地块数量。他常常是掰着手指算钱，“有些地好，但钱不够就拿不了”。<br/><br/>
          在集中供地的竞拍场上，一轮土拍中，资金实力雄厚的规模房企，报名的地块数量可以达到十几块。但陈生通常只能报名1-2块，这意味他几乎没有失手的机会，对地块要更加“势在必得”。一旦颗粒无收，就意味着公司在这个城市将“无米下锅”——公司总共进入的城市屈指可数，这将直接影响到企业生存。<br/><br/>
          更大的压力在于，在小公司，拿地不能出任何差错，投资需要绝对精准，“因为2022年拿地的钱都是靠2021年销售回款来的，一旦项目滞销就意味着公司现金流出现严重问题。”陈生表示，小企业与大公司的最大区别在于，但凡有一个项目拿错了，公司很可能就搭在上面了，道理很简单：十个项目坏一个还行，但是两个项目坏一个，就生存不下去了。<br/><br/>
          全国集中供地刚开始时，陈生觉得中小型房企还算是有一些机会的：热门地区可能对中小型房企来说不太友好，但可以在22个重点城市之外寻找机会。但目前的市场行情下，大部分城市都意味着滞销和亏本，陈生只能选择加入重点城市的厮杀中。<br/><br/>
          除了“安全”外，还有利润方面的考虑。陈生解释，放眼全国，除了上海，几乎没有哪个城市的利润可以做到10个点以上了。杭州以前可以做到8-10个点的利润，但2022年，陈生把杭州但凡超过6个点利润的项目都归为“好项目”之列。<br/><br/>
          对中小型房企而言，进入一个新的热门城市难度可以称作“hard”级。许多房企进入新城市，靠的都是牺牲利润空间，比如2021年杭州首次土拍时，正荣首进杭州，就在临安拍出了超过18000元/平方米的楼面价，这刷新了当时杭州临安的地价纪录。如果加上自持9%的成本，折算实际地价接近2万元/平方米，而该地块毛坯限价仅每平方米23000元左右。<br/><br/>
          “我们中小企业是亏不起的。”陈生表示。为了拿地，尤其是为了进入某些城市时，有的小房企会把成本算得很低，再将销售端的价格算得很高，“比如车位算50万元一个”，其实还没开售就已经知道亏了。<br/><br/>
          实际上，觉得拿地困难的不仅仅陈生这样在小型房企工作的投拓负责人，一家TOP20房企的投资负责人告诉经济观察报，2022年，如果还只是将目标聚焦在招拍挂上会“很难受”，得想找一些其他的渠道和方法，比如做一些代建业务和找一些金融机构合作，看看
          AMC（资产管理机构）收的不良资产，是不是能一起盘活。<br/><br/>
          “自始至终，做面包都是我们引以为豪的能力。”这位投资负责人说，所以即使现在自己的公司已经不再有钱拿新的项目，他仍还是会去看项目。“我们没有钱拿项目，不代表其他人没有钱拿项目。企业从政府这边买地，来烤面包卖给消费者，只要这两端都有需求，就可以做个手艺人来帮别人干”。<br/><br/>
          但对小企业来说，这条路很难走通。陈生感叹，大部分中小型房企在代建方面并不具有竞争优势。“道理也很简单，大企业不会找中小企业代建，中小企业代建业务只能找更小的企业代建。因为自己在做面包方面没有竞争力”。此外，做代建业务很难帮助中小企业快速发展。“不投钱就意味着不会亏钱，但也不挣钱”。<br/><br/>
          代建一般包括工程和营销两方面，陈生介绍，代建费用一般在4-7个点左右，现在7个点的很少了，通常给5个点费用，如果加上人工工资、运营等方面3-4个点的成本，代建的纯利润一般在1-2个点左右。“这意味着100亿元的项目能赚一个亿左右，而自己开发的利润则在6亿-8亿元左右，差价非常大”。<br/><br/>
          对中小企业来说，如何长大成为摆在眼前的困难，但陈生认为，2016-2018年是行业里最后一轮上升周期，这也是中小企业冲规模的最后机会，错过了这波机会，靠目前销售回款等自有资金滚动，很难再长大。<br/><br/>
          不过，相比于一些债台高筑、摇摇欲坠的民企，陈生所在企业资金情况并不算糟糕。“2021年的地都拿对了，销售回款率大约90%，没有踩错一步节奏”，但陈生公司仍然开启大规模的降薪裁员行动，一些部门被裁撤得只剩下部门负责人“孤军作战”，陈生也在11月份签署了薪资打折的协议。<br/><br/>
          “这是出于对未来生存的悲观预期。”陈生说，规模小，意味着资金面上的弱势和抗风险能力薄弱。“这一波，我们虽然没倒下了，但也只是活着而已”，但企业本身的能力、竞争力都没有变。竞争力作为企业本身最大的短板如果不补齐，是无法靠行业环境变化一直存活下去的。行业洗牌中，虽然有一些企业倒下了，但留下来的对手都是“大鳄”。“小对手死了，但是大对手更加强大了”，陈生将每一年都视为过河，“今年河里的水突然干了，蹚过去了，但是遇见下一条河的时候怎么办？”<br/><br/>

        </div>
      </template>
      <template v-if="$route.params.type === '5'">
        <div>2022年医疗健康五大趋势，这些设备是风口</div>
        <div>科技日报</div>
        <div>
          美国《福布斯》双周刊网站在近日的报道中，列出了2022年医疗保健领域的五大技术趋势：远程医疗、个性化医疗、基因组学和可穿戴设备等领域将迎来高速增长，业界人士也将进一步利用人工智能（AI）、云计算、虚拟现实和物联网（IoT）等技术，开发并提供新的更好的疗法和服务。<br/><br/>
          <span style="font-weight: bold">可穿戴设备助力远程医疗</span><br/><br/>
          德勤会计师事务所的分析师表示，在新冠疫情暴发头几个月，远程医疗咨询的比例从0.1%飙升至43.5%，未来这一比例还会增加，原因显而易见——这一趋势有望让更多病患获得救命的机会。<br/><br/>
          为实现这一目标，新一代可穿戴设备所配备的心率、压力和血氧检测器，使医生们能够实时准确地监测病患的生命体征。疫情期间，有的地方建立了“虚拟医院病房”，医生们利用中央通信基础设施监测身处多地的患者的治疗情况，美国宾夕法尼亚急救医学中心正在开发更高级版本的“虚拟急诊室”。<br/><br/>
          2022年，远程医疗方法将扩展到其他领域，如改善患者的心理健康，以及为手术和重症康复患者提供持续后续护理等。<br/><br/>
          机器人和物联网将在这一趋势中发挥重要作用，比如，智能技术（机器学习）将在传感器检测到需要干预或摄像头发现老年人在家跌倒时提醒专业人士。<br/><br/>
          <span style="font-weight: bold">VR用于临床培训和治疗</span><br/><br/>
          虚拟现实（VR）耳机目前已被用于培训医生，使他们能了解人体的工作原理。VR也被用于治疗，比如用于训练自闭症儿童获得社交和生活技能；还被用于促进认知行为疗法，以帮助治疗慢性疼痛、焦虑甚至精神分裂症。已开发出基于VR的疗法，可让患者在安全无威胁的环境中克服恐惧和治疗精神病。<br/><br/>
          2022年，增强现实技术在医疗领域的应用也将继续增长。<br/><br/>
          如美国AccuVein公司的产品旨在通过检测血流的热信号并在患者手臂上突出显示，使医生和护士在需要注射时更容易定位静脉。微软公司的全息透镜系统被用于外科手术室，外科医生们可接收他们所看到物体的实时信息，并与其他可能正在观察手术的专业人员或学生分享他们的观点。<br/><br/>
          <span style="font-weight: bold">利用AI和机器学习理解医学数据</span><br/><br/>
          与在其他行业一样，AI在医疗保健领域发挥的重要作用包括理解大量杂乱、非结构化数据。这些数据包括X光、CT和MRI扫描等获得的数据、有关新冠等传染病疫苗分发的数据以及活细胞基因组数据，甚至医生手写的笔记等。<br/><br/>
          AI也可成为医生的好帮手，并提升他们的工作技能。如AI可对患者进行简单接触和分类，以便临床医生腾出时间开展更有价值的工作。而远程医疗服务提供商巴比伦健康公司使用人工智能聊天机器人，通过自然语言处理，收集患者的症状信息并直接向合适的医疗专业人员咨询。<br/><br/>
          未来几年，人工智能将对预防医学领域产生深远影响。预防医学不通过事后提供治疗来对疾病作出反应，而是预测疾病将在何时何地发生，并在疾病发生之前制定解决方案。这包括预测传染病暴发的地点、病患的再住院率以及饮食、锻炼、运动等生活方式因素。这些工具能够比传统分析过程更有效地发现巨大数据集中的模式，从而实现更准确的预测并最终改进疗效。<br/><br/>
          <span style="font-weight: bold">“数字孪生”取代动物试验</span><br/><br/>
          “数字孪生”目前正出现在多个领域，“数字孪生”指根据真实世界获得的数据创建一个数字模型，可用于模拟任何系统或过程。<br/><br/>
          在医疗领域，这一趋势包括对“虚拟患者”——那些测试药物和治疗的人进行数字模拟，目的是缩短新药从设计阶段进入通用阶段所需的时间。<br/><br/>
          就目前的情况而言，人体器官和系统的“数字孪生”更接近现实，这使医生能够探索不同器官出现疾病的原因并开展治疗试验，而不需要开展昂贵的人体或动物试验。美国于2014年启动的“活心脏”项目，旨在创建一个开源的人类心脏的“数字孪生”；欧盟类似的“神经孪生”项目则模拟大脑中电场的相互作用，希望能为阿尔茨海默病带来新疗法。<br/><br/>
          <span style="font-weight: bold">个性化医疗与基因组学</span><br/><br/>
          包括基因组学、AI和“数字孪生”等现代医疗技术，将使医生们能采取更个性化的方法，根据患者自身的情况量身定制疗法。<br/><br/>
          例如，瑞典Empa医疗中心使用AI和建模软件来预测个别患者止痛药的确切剂量。对于慢性疼痛患者来说，适量药物能有效并提升他们的生活质量，但剂量过高，则极其危险。<br/><br/>
          诺和诺德制药公司与数字健康公司Glooko合作创建了个性化糖尿病监测工具，可以根据患者的血糖读数和其他特定因素，为患者提供饮食、运动和疾病管理等方面的个性化建议。<br/><br/>
          研究基因以及利用技术绘制个体基因组的基因组学，对于研制个性化药物特别有用，有望催生癌症、关节炎和老年痴呆症等疾病的新疗法。<br/><br/>
          医疗器械
          ETF（159883）为市场首只，也是目前规模最大的一只医疗器械主题ETF。该ETF追踪中证全指医疗器械指数，成分股涵盖医疗设备（权重29.72%）、医美（权重11.37%）、医疗耗材（权重27%）、IVD（体外诊断产品，权重31.91%）等细分行业，前十大权重分别为迈瑞医疗、爱美客、欧普康视、乐普医疗、万泰生物、金域医学、健帆生物、鱼跃医疗、英科医疗、华大基因等股。对于普通投资者而言，医疗器械种类繁杂且研究门槛较高，个股波动大，个人投资者研究难度较高，借道指数基金更省心，还可分散个股投资风险。场内用户可通过医疗器械ETF（159883），<span
            style="font-weight: bold">场外用户可通过联接基金（A份额013415，C份额013416）进行布局。</span><br/><br/>
        </div>
      </template>
      <template v-if="$route.params.type === '6'">
        <div>楼市展望与行业前瞻：地产行业在阵痛中寻找未来</div>
        <div>证券日报 2021-11-08 21:11</div>
        <div>今年的房地产市场没有“金九”，也没有“银十”。<br/><br/>
          克而瑞研究中心发布的2021年1月-9月房地产企业销售TOP100排行榜显示，百强房企单月全口径销售金额同比下降36%。10月，房地产市场持续降温，逾8成百强房企单月业绩同比降低，其中44家房企业同比降幅大于30%。<br/>
          受宏观调控政策和金融环境影响，今年地产交易步入下行周期。自二季度起，重点一、二线城市陆续出现成交量同比下跌。预计年底房地产市场还是不容乐观，成交或将缓步下行。另一方面，去化压力之下，全国房价开始出现下跌趋势。<br/><br/>
          “三稳”调控目标不变<br/><br/>
          2021年下半年以来，房地产市场下行趋势明显。9月29日，人民银行、银保监会联合召开房地产金融工作座谈会，要求金融部门准确把握和执行好房地产金融审慎管理制度，加快完善住房租赁金融政策体系，并强调“稳地价、稳房价、稳预期”目标。<br/><br/>
          贝壳研究院首席市场分析师许小乐对此表示，当前整个行业处于转型期，平衡好行政调控和市场化配置，将有助于平滑转型带来的市场波动，保持整体市场平稳发展。<br/><br/>
          中泰国际认为，中期看，人口流动与经济发展仍是房价的主要决定因素，核心城市的房价仍将受到人口流入与经济增长的支撑，部分人口流出、产业薄弱的城市将见到房地产市场量价下降，因此房地产市场将进一步分化。<br/><br/>
          市场分析人士指出，地产政策层面全方位严格调控的最艰难时期已经过去。预计房企资金压力将有所缓解，按揭贷款、开发贷额度有望松动，尤其是按揭贷款，刚需及合理改善型住房需求或将迎来修复。<br/><br/>
          寻找第二增长曲线成新方向<br/><br/>
          数据显示，截至11月3日，在A股121家上市房地产企业中，前三季度营业收入同比下滑者达40家，归属母公司净利润同比下滑者达52家，占比超四成；31家房企亏损。<br/><br/>
          2021年前三季度，121家A股上市房企营业收入共计1.87万亿元，同比增长23.2%；归母净利润却仅827亿元，同比下降29.5%，剔除华夏幸福（亏损134.6亿元）、蓝光发展（亏损67.1亿元）后，行业归母净利润仍同比下降4.2%。<br/><br/>
          行业龙头也未能幸免，从营业收入总规模50强房企情况看，前三季度营收同比下滑房企有13家；净利润同比下滑房企则高达23家，占比近一半。万科、金地、绿地控股前三季度均录得归母净利润大幅下滑。<br/><br/>
          历史告诉我们，纯粹的情绪并无意义，理智才是通往未来的钥匙。当前的资本市场中，第二增长曲线已然成为市场看待地产企业未来的一面镜子。10月28日晚间，万科公告将持续加码“地产+”业务，董事会计划向股东大会申请授权在不超过300亿元的直接债务融资工具，长租公寓、物流地产、产业园、养老公寓等多元化转型业务是主要方向。<br/><br/>
          在稳地价、稳房价、稳预期的“三稳”目标，以及房住不炒、租购并举和保障民生的主旋律下，我国新房开发和销售速度，以及二手房交易量会有所放缓，但并不会出现断崖式下降。家庭结构快速小型化、加速到来的换房与改善需求、数亿流动人口的租赁住房需求、庞大的活跃老年人的养老住房需求，构成了中国房地产市场多元化需求的底色。<br/><br/>
          发达国家经验表明，即使经济进入相当成熟阶段之后，房地产业对经济增长的贡献并不会自然减弱，甚至会因为房地产服务业以及各类住房衍生产业的发展壮大而进一步增加。从数据上看，全球主要发达国家的房地产业GDP占比一般达到10%-12%，我国目前只有7%左右；发达国家的房地产整个上下游产业链合计占比往往超过GDP的20%，我国只有15%左右。<br/><br/>
          结合国内行业现实发展情况以及国际实践经验来看，未来，中国住房将从“钢筋水泥”转向“有温度的”居住服务业，成为中国经济内循环的重要支撑。房地产服务细分行业完全有可能再造一个房地产。例如，未来我国租赁人口将超过2.5亿人，这一庞大的需求基数将刺激租赁服务业的全面增长，从租赁住房的出租、装修、改造、维护以及租后的保洁、搬家等服务都是潜在的增长点，将会是一个2万亿-3万亿规模的市场。<br/><br/>
          居住服务市场潜力可期<br/><br/>
          9月29日，贝壳研究院联合空白研究院发布的《超越交易：迎接品质服务的美好时代》报告认为，居住服务领域进入4.0时代。报告分析，居住服务业将成为我国经济增长的新兴增长点，无论是对于增长，还是对于就业创造，居住服务业的崛起都将成为一个全新的时代命题。<br/><br/>
          行业诸多玩家已开始在家装赛道中寻找新增长点。2020年，世茂集团与红星美凯龙正式签署合作协议，达成全面战略合作伙伴关系。双方联手推出了家居家装品牌“世茂美凯龙”，以期合行业全产业链条，打通家装全周期服务。<br/><br/>
          今年，红星美凯龙公布2021年装修版块业绩目标，给家装业务定下了80亿元合同额、30亿元收入的目标。无独有偶，居然之家在2020年与阿里成立了躺平家设计平台，提出布局家装版图，并计划3-5年内实现100亿。<br/><br/>
          与此同时，贝壳也在新家装赛道展开长期布局。早在2019年，贝壳找房推出了全新家装服务品牌“被窝家装”，正式进入家装行业。今年7月，贝壳宣布与圣都家装达成协议，将收购圣都100%的股权。<br/><br/>
          在近期的万科2021业务交流会上，万科集团董事会主席郁亮引用了日本大和房建转型成功的例子：在经历了日本房地产行业大溃退之后，大和房建通过在非主业的努力，坐上了日本租赁公寓、物流及代建业务的头把交椅。如今已连续十余年位列世界五百强300名左右。<br/><br/>
          正如分析人士所言，“中国所有的生意，都值得重做一遍。”在政策底部已现，地产行业步入转型关键期的当下，无论是“老中介”还是“新家装”，楼市有更大的空间，行业中的玩家还有“重做一遍”的机会。（董枳君）<br/><br/>
        </div>
      </template>
      <template v-if="$route.params.type === '7'">
        <div>全球独角兽企业500强报告发布：医疗健康赛道前景看好</div>
        <div>2021-09-25 16:49·光明网</div>
        <div>中新网青岛9月25日电
          由青岛市人民政府和中国人民大学中国民营企业研究中心联合主办的“第三届全球独角兽企业500强大会(2021)”日前在青岛市举办。会议期间全球独角兽企业500强大会秘书长解树江发布了《全球独角兽企业500强发展报告(2021)》。<br/><br/>
          报告显示，2021全球独角兽企业500强中，企业服务赛道有112家，占比22.4%，位居赛道第一，总估值为5937.63亿美元；生活服务赛道有86家，占比17.2%，位居赛道第二，总估值为4108.55亿美元；智能科技赛道有69家，总估值为3295.75亿美元，位居赛道第三；剩余则分别在金融科技、医疗健康、文旅传媒、汽车交通、物流服务、教育科技、材料能源、航空航天8个板块，数量分别为58、52、38、33、22、15、9、6。<br/><br/>
          在2021年全球独角兽企业500强榜单中，医疗健康赛道共有52家企业登榜，其中23家为新晋企业，更新率高达44%，总估值为2176.82亿美元，较2020年上涨661.67亿美元，同比增长率同样接近44%。在疫情中，生物制药与技术持续获得资本的关注，多国对于医疗研发、制药和器材的重视得到不同程度的启发，政府、民众和资本对于医疗健康的多方面投入比例大幅上升，加之随着全球网络基础建设的完善，远程医疗、智慧医疗等行业的发展条件成熟，此类独角兽企业的发展也迎来了这一风口，医疗健康领域的各个细分行业独角兽企业在资金吸引和业务拓展方面都获得了新的增长点。<br/><br/>
          《全球独角兽企业500强发展报告(2021)》是继2019年之后发布的第三个关于全球独角兽企业500强的报告，报告基于2021年全球独角兽企业数据库，按照全球独角兽企业500强评估标准，采用人机共融智能技术，遴选出全球前500家独角兽企业。该报告全面阐述了全球独角兽企业500强的最新发展动态，分析了全球独角兽企业500强基本格局和发展特征，并对全球独角兽企业500强的未来发展进行了展望。<br/><br/>
          本次会议由青岛市人民政府和中国人民大学中国民营企业研究中心联合主办，青岛市民营经济发展局、青岛即墨区政府及北京隐形独角兽信息科技院承办。<br/><br/>
          2021年全球独角兽企业500强评价标准有五方面：一是公司估值在10亿美元以上；二是拥有独创性或颠覆性技术；三是拥有难以复制的商业模式；四是成立时间10年左右；五是符合现行政策导向，不存在重大负面舆情。本报告评估基准日为2021年7月31日。(中新财经工作室)<br/><br/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "industryTrendsDetails",
}
</script>

<style scoped lang="scss">
.industryTrendsDetails {
  .body {
    width: 65%;
    margin: 40px auto 0;

    & > div {
      font-size: 16px;
      font-weight: 400;
      color: #4C4C4C;
      line-height: 28px;

      &:nth-child(1) {
        font-size: 25px;
        font-weight: 500;
        color: #4C4C4C;
        line-height: 38px;
      }

      &:nth-child(2) {
        font-size: 16px;
        color: #999999;
        line-height: 20px;
        padding: 20px 0 39px 0;
      }
    }
  }
}
</style>